import { useState } from 'react';
import {
  ControllerComplaintDependencies as dpnd,
  useComplaintController,
} from '@app/book/domain/controllers';
import { getItem } from '@app/shared/utils/storage';
import { Constants } from '@app/shared/config/Constants';
import { CountriesAllowed } from '@app/book/infra/config/CountriesAllowed';
import { PeruFormData, ArgentinaFormData, FormData } from '@app/book/domain/models';
import { CreateComplaintRequest } from '@app/book/domain/models';
import { ComplaintBook } from '@app/book/use_cases/adapter';
import { Routes } from '@app/book/infra/config/Routes';

export const useComplaint: useComplaintController = ({ dependencies }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const service = (dependencies as dpnd).service;
  const { setGenerated } = (dependencies as dpnd).useComplaintContext();
  const navigate = (dependencies as dpnd).useNavigate();
  const {
    country: { shortName },
  } = (dependencies as dpnd).usePublicEnv();
  const { data, isLoading } = (dependencies as dpnd).useQuery({
    queryKey: 'pattern',
    queryFn: ({ signal }) => {
      return service.getPattern({
        countryCode: shortName,
        mock: getItem(Constants.MOCK_FLAG, false),
      });
    },
    retry: 2,
  });

  const { mutate, isLoading: isLoadingSaving } = (dependencies as dpnd).useMutation({
    mutationKey: 'createComplaint',
    mutationFn: (value: CreateComplaintRequest) => {
      return service.createComplaint({
        ...value,
        countryCode: shortName,
        mock: getItem(Constants.MOCK_FLAG, false),
      });
    },
    onSuccess: (response) => {
      const url = Routes.COMPLAINT_SUCCESS.replace(':claimId', response.claimId).replace(
        ':claimGUID',
        response.claimReferenceGUID,
      );
      setGenerated(true);
      navigate(`${Routes.BASE}/${url}`);
    },
    onError: () => {
      setShowErrorModal(true);
    },
    retry: 2,
  });

  const onSave = (value: FormData) => {
    if (!isLoadingSaving) {
      /**
       * Validation for peru body, until
       * backend update the contract
       *  */
      const request =
        shortName === CountriesAllowed.pe
          ? ComplaintBook.ComplaintFormToRequest(value as PeruFormData)
          : value;
      mutate(request as CreateComplaintRequest);
    }
  };

  const onCancel = () => {
    navigate(`${Routes.BASE}`);
  };

  const closeError = () => {
    setShowErrorModal(false);
  };

  return {
    data,
    onSave,
    onCancel,
    loading: isLoadingSaving || isLoading,
    showErrorModal,
    closeError,
    country: shortName,
  };
};
