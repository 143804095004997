import { ComplaintBookRepository } from '@app/book/data/repositories';
import {
  CreateComplaintRequest,
  CreateComplaintResponse,
  PatternRequest,
  PatternResponse,
  GeneratePDFRequest,
  GeneratePDFResponse,
} from '@app/book/domain/models';
import { ComplaintBook } from '@app/book/domain/services';

export class ComplaintBookService implements ComplaintBook {
  private repository: ComplaintBookRepository;
  constructor(repository: ComplaintBookRepository) {
    this.repository = repository;
  }

  public getPattern(payload: PatternRequest): Promise<PatternResponse> {
    return this.repository.getPattern(payload);
  }

  public createComplaint(payload: CreateComplaintRequest): Promise<CreateComplaintResponse> {
    return this.repository.createComplaint(payload);
  }
  public generatePDF(payload: GeneratePDFRequest): Promise<GeneratePDFResponse> {
    return this.repository.generatePDF(payload);
  }
}
