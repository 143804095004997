import { useState, useCallback } from 'react';
import {
  useComplaintSuccessController,
  ControllerComplaintSuccessDependencies as Dpnd,
} from '@app/book/domain/controllers';
import { getItem } from '@app/shared/utils/storage';
import { Constants } from '@app/shared/config/Constants';
import { Constants as CBook } from '@app/book/infra/config/Constants';
import { Routes } from '@app/book/infra/config/Routes';
import { getWindow } from '@app/shared/utils/window';

type RouterParams = {
  claimId: string;
  claimGUID: string;
};

export const useComplaintSuccess: useComplaintSuccessController = ({ dependencies }) => {
  const [file, setFile] = useState<Blob>();
  const navigate = (dependencies as Dpnd).useNavigate();
  const { claimGUID, claimId } = (dependencies as Dpnd).useParams<RouterParams>();
  const {
    country: { shortName },
  } = (dependencies as Dpnd).usePublicEnv();
  const { refetch, isLoading, isError } = (dependencies as Dpnd).useQuery({
    queryKey: 'generatePDF',
    queryFn: ({ signal }) => {
      return (dependencies as Dpnd).service.generatePDF({
        claimGUI: claimGUID as string,
        claimId: claimId as string,
        countryCode: shortName,
        mock: getItem(Constants.MOCK_FLAG, false),
      });
    },
    enabled: false,
    onSuccess: (result) => {
      setFile(result);
    },
    onError: () => {},
  });

  /**
   * downloadClaimPDF
   *
   * @description Function to force download pdf to user
   * @param {Blob} fileData - Blob
   */
  const downloadClaimPDF = (fileData: Blob) => {
    try {
      const fileBlob = new Blob([fileData], { type: 'application/pdf' });
      const file = URL.createObjectURL(fileBlob);
      const a = document.createElement('a');
      a.href = file;
      a.download = `${claimId}.pdf` || 'download';
      a.setAttribute('id', 'download_helper');

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(file);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };

      a.addEventListener('click', clickHandler, false);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * printClaimPDF
   *
   * @description Function to open new window with pdf information
   * @param {Blob} fileData - Blob
   */
  const printClaimPDF = (fileData: Blob) => {
    const fileBlob = new Blob([fileData], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(fileBlob);
    const wn = getWindow();
    if (wn) {
      wn.open(fileURL);
    }
  };

  const gatewayHandle = (
    action: CBook.PRINT_PDF_ACTION | CBook.DOWNLOAD_PDF_ACTION,
    fileData: Blob,
  ) => {
    if (action === CBook.DOWNLOAD_PDF_ACTION) {
      return downloadClaimPDF(fileData as Blob);
    }

    if (action === CBook.PRINT_PDF_ACTION) {
      return printClaimPDF(fileData as Blob);
    }
  };
  /**
   * getPDFInformation
   *
   * @description Function to call service to get information about complaint
   * to allow user download pdf or print it.
   * @returns {void}
   */
  const getPDFInformation = async (action: CBook.PRINT_PDF_ACTION | CBook.DOWNLOAD_PDF_ACTION) => {
    if (file) {
      return gatewayHandle(action, file);
    }
    const result = await refetch();
    if (!result.isError) {
      gatewayHandle(action, result.data as Blob);
    }
  };

  const finishProcess = () => {
    navigate(Routes.BASE);
  };

  return {
    getPDFInformation,
    loading: isLoading,
    error: isError,
    claimId: claimId as string,
    finishProcess,
    country: shortName,
  };
};
