import React from 'react';
import type { FC, PropsWithChildren, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface RouterApp extends PropsWithChildren {
  isAllowed: boolean;
  redirectTo?: string;
  children: ReactElement;
}

export const ProtectedRouter: FC<RouterApp> = ({ children, isAllowed, redirectTo }) => {
  if (!isAllowed) {
    return <Navigate to={redirectTo as string} />;
  }
  return children ? children : <Outlet />;
};
