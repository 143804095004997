import React from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import fenixTheme from '@pedidosya/web-fenix/theme';
import Error from '@pedidosya/web-fenix/pages/Error';
import { sentryHub } from '@app/shared/utils/sentry';
import type { FC } from 'react';
import type { ErrorBoundaryProps } from './ErrorBoundary.type';

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ error, ...props }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHub.captureException(error);

  return (
    <ThemeProvider theme={fenixTheme}>
      <Error primaryActionClick={() => {}} primaryActionLabel="Tuvimos un problema" />
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
