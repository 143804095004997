import {
  CreateComplaintRequest,
  CreateComplaintResponse,
  GeneratePDFRequest,
  GeneratePDFResponse,
  PatternRequest,
  PatternResponse,
} from '@app/book/domain/models';
import { ComplaintBook } from '@app/book/domain/repositories';
import { Urls } from '@app/book/infra/config/Urls';
import { HttpClient } from '@app/shared/utils/http';

export class ComplaintBookRepository implements ComplaintBook {
  public async getPattern(payload: PatternRequest): Promise<PatternResponse> {
    const result = await HttpClient.getInstance().get(
      `${Urls.PATTERN}?countryCode=${payload.countryCode}&mock=${payload.mock}`,
    );
    return result.data;
  }
  public async createComplaint(payload: CreateComplaintRequest): Promise<CreateComplaintResponse> {
    const result = await HttpClient.getInstance().post(
      `${Urls.CREATE_COMPLAINT}?countryCode=${payload.countryCode}`,
      payload,
    );
    return result.data;
  }

  public async generatePDF(payload: GeneratePDFRequest): Promise<GeneratePDFResponse> {
    const result = await HttpClient.getInstance().get(
      `${Urls.GENERATE_PDF}/${payload.claimId}/${payload.claimGUI}?countryCode=${payload.countryCode}&mock=${payload.mock}`,
      { responseType: 'blob' },
    );
    return result.data;
  }
}
