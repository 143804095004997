import { CountriesAllowed } from './CountriesAllowed';

export const NavbarTitle = {
  [CountriesAllowed.ar]: {
    id: 'navbar_title_ar',
  },
  [CountriesAllowed.pe]: {
    id: 'navbar_title_pe',
  },
};
