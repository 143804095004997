import { PeruFormData } from '@app/book/domain/models/PeruForm';
import { CreateComplaintRequest } from '@app/book/domain/models';

export class ComplaintBook {
  static ComplaintFormToRequest(data: PeruFormData) {
    const time = data.complaintTime.split(':');
    return {
      address: data.address,
      complaintType: data?.complaintType,
      document: data?.documentNumber ? parseInt(data.documentNumber) : null,
      contractedAssetAmount: data?.claimAmount ? parseFloat(data.claimAmount) : null,
      contractedAssetDescription: data?.productDescription,
      contractedAssetType: data?.productType,
      countryCode: '',
      email: data?.email,
      fullName: data?.fullName,
      isUnderAge: data?.underAge,
      mock: '',
      phone: data?.phone,
      problemAdopterProvider: data?.problemAdopterProvider,
      problemDate: data?.complaintDate,
      problemDescription: data?.complaintDescription,
      problemHour: time[0],
      problemMinutes: time[1],
      problemSolution: data?.complaintSolution,
      tutorAddress: data?.parentAddress,
      tutorEmail: data?.parentEmail,
      tutorFullName: data?.parentFullName,
      tutorPhone: data?.parentPhone,
    } as CreateComplaintRequest;
  }
}
