import React from 'react';
import type { FC, HTMLAttributes } from 'react';
import { Content, ImageContainer, TextContainer } from './CardInformation.style';
import CardContainer from '@pedidosya/web-fenix/molecules/CardContainer';
import Image from '@pedidosya/web-fenix/foundations/Image';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

interface CardInformationProps extends HTMLAttributes<HTMLDivElement> {
  message: string;
  image: string;
}

export const CardInformation: FC<CardInformationProps> = ({ image, message, ...props }) => {
  return (
    <CardContainer withShadow={true} withBorder={false} {...props}>
      <Content>
        <ImageContainer>
          <Image src={image} width={78} height={78} alt="Image" />
        </ImageContainer>
        <TextContainer>
          <Typography token="font-body-midcontrast-sentence-large" component="p">
            {message}
          </Typography>
        </TextContainer>
      </Content>
    </CardContainer>
  );
};
