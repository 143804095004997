import { defineMessages } from 'react-intl';

const messages = defineMessages({
  navbar_title_pe: {
    id: 'navbar.title_pe',
    defaultMessage: 'Libro de reclamaciones',
  },
  navbar_title_ar: {
    id: 'navbar.title_ar',
    defaultMessage: 'Libro de quejas online',
  },
});

export default messages;
