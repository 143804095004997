import { QueryClient } from 'react-query';
import { sentryHub } from '@app/shared/utils/sentry';

export default new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  logger: {
    log: (params) => {
      sentryHub.captureMessage(params);
    },
    warn: (e) => {
      sentryHub.captureException(e);
    },
    error: (e) => {
      sentryHub.captureException(e);
    },
  },
});
