import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  vertical-align: middle;
  align-content: center;
  align-items: center;
  padding: 18px;
`;

export const ImageContainer = styled.div`
  min-width: 78px;
`;

export const TextContainer = styled.div`
  text-align: left;
`;
