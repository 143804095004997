import React from 'react';
import type { FC } from 'react';
import { Router } from '@app/book/presentation/components/Router';
import { IOCProvider } from '@app/shared/contexts/IOCProvider';
import { Depedencies } from '@app/book/domain/models';
import { useNavigate, useParams } from 'react-router-dom';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { useIntl } from 'react-intl';
import { useHome, useComplaint, useComplaintSuccess } from '@app/book/use_cases/controllers';
import { ComplaintBookRepository } from '@app/book/data/repositories';
import { ComplaintBookService } from '@app/book/use_cases/services';
import { QueryClientProvider, useQuery, useMutation } from 'react-query';
import { ComplaintProvider, useComplaintContext } from '@app/book/data/contexts';
import { useLinkContext, LinkProvider } from '@app/shared/contexts/LinkProvider';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import QueryClient from '@app/shared/utils/query_client';

export const Book: FC = () => {
  const repository = new ComplaintBookRepository();
  const service = new ComplaintBookService(repository);
  const dependencies: Depedencies = {
    useNavigate,
    usePublicEnv,
    useIntl,
    useHome,
    service,
    useQuery,
    useMutation,
    useComplaint,
    useParams,
    useComplaintSuccess,
    useComplaintContext,
    useLinkContext,
    useIsWebView,
  };

  return (
    <main>
      <QueryClientProvider client={QueryClient}>
        <IOCProvider dependencies={dependencies}>
          <ComplaintProvider>
            <LinkProvider>
              <Router />
            </LinkProvider>
          </ComplaintProvider>
        </IOCProvider>
      </QueryClientProvider>
    </main>
  );
};
