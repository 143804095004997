import React from 'react';
import PropTypes from 'prop-types';
import fenixTheme from '@pedidosya/web-fenix/theme';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import GlobalStyles from '@pedidosya/web-fenix/theme/GlobalStyles';
import { SentryProvider } from '@app/shared/contexts/SentryProvider';
import { createIntlProvider, getMessages } from '@app/shared/contexts/IntlProvider';
import { Book } from '@app/book/main';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from '@app/shared/contexts/PublicEnv';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const Root = ({ context, messages, name }) => (
  <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
    <SentryProvider>
      <FenixFonts />
      <ThemeProvider theme={fenixTheme}>
        <IntlProvider initialMessages={messages}>
          <GlobalStyles />
          <Book />
        </IntlProvider>
      </ThemeProvider>
    </SentryProvider>
  </PublicEnvProvider>
);

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};
Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

export default Root;
