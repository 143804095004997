import { useMemo } from 'react';
import { Routes } from '@app/book/infra/config/Routes';
import { useHomeController, ControllerDependencies as dpnd } from '@app/book/domain/controllers';
import { generateLink, ACTIONS } from '@app/shared/utils/deeplink';

export const useHome: useHomeController = ({ dependencies }) => {
  const navigate = (dependencies as dpnd).useNavigate();
  const {
    device,
    deviceOS,
    country: { shortName },
  } = (dependencies as dpnd).usePublicEnv();

  const goToComplaint = () => {
    navigate(Routes.COMPLAINT);
  };

  const link = useMemo(() => {
    const result = generateLink(device, deviceOS, ACTIONS.HELP_CENTER, {});
    return result;
  }, [device, deviceOS]);

  return {
    goToComplaint,
    link,
    country: shortName,
  };
};
