import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Container } from '@app/shared/components/Container';
import {
  BoxContainer,
  Box,
  Title,
  ButtonContainer,
  FormContainer,
  FormContent,
  Link,
} from './Home.style';
import { CardInformation } from '@app/book/presentation/components/CardInformation';
import { WithDependencies } from '@app/shared/contexts/IOCProvider';
import { Depedencies } from '@app/book/domain/models';
import { NavbarTitle } from '@app/book/infra/config/NavbarTitle';
import Button from '@pedidosya/web-fenix/atoms/Button';
import ClaimImage from '@app/assets/images/claim.svg';
import ComplaintImage from '@app/assets/images/complaint.svg';
import Translations from './Home.translation';

interface HomeProps {
  dependencies?: Depedencies;
}

export const Home: FC<HomeProps> = WithDependencies(({ dependencies }) => {
  const { formatMessage } = (dependencies as Depedencies).useIntl();
  const { goToComplaint, link, country } = (dependencies as Depedencies).useHome({ dependencies });
  const title = useMemo(() => {
    return (NavbarTitle as any)[country]
      ? formatMessage((Translations as any)[(NavbarTitle as any)[country].id])
      : '';
  }, [country]);

  return (
    <FormContainer>
      <FormContent>
        <Container>
          <Title token="font-headline-highcontrast-medium" component="h3" textAlign="center">
            {title}
          </Title>
          <Title
            token="font-body-midcontrast-sentence-large"
            component="p"
            textAlign="center"
            margin="18px 0px 18px 0px"
          >
            {formatMessage(Translations.help_text, {
              link: <Link href={link}>{formatMessage(Translations.onlineHelp)}</Link>,
            })}
          </Title>
          <Title
            token="font-headline-highcontrast-medium"
            component="h3"
            textAlign="center"
            margin="24px 0px 24px 0px"
          >
            {formatMessage(Translations.type_complaint)}
          </Title>
          <BoxContainer>
            <Box>
              <CardInformation
                message={formatMessage(Translations.type_complaint_message_attention)}
                image={ClaimImage}
              />
            </Box>
            <Box>
              <CardInformation
                message={formatMessage(Translations.type_complaint_message_service)}
                image={ComplaintImage}
              />
            </Box>
          </BoxContainer>
          <Title
            token="font-headline-highcontrast-medium"
            component="h3"
            textAlign="center"
            margin="32px 0px 18px 0px"
          >
            {formatMessage(Translations.after_complaint_title)}
          </Title>
          <Title
            token="font-body-midcontrast-sentence-large"
            component="p"
            textAlign="center"
            margin="18px 0px 32px 0px"
          >
            {formatMessage(Translations.after_complaint_message)}
          </Title>
          <ButtonContainer>
            <Button
              label={formatMessage(Translations.enter)}
              size="large"
              hierarchy="primary"
              onClick={goToComplaint}
            />
          </ButtonContainer>
        </Container>
      </FormContent>
    </FormContainer>
  );
});
