import React, { useCallback, useMemo } from 'react';
import { NavBar } from '@app/book/presentation/components/NavBar';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Routes } from '@app/book/infra/config/Routes';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { getWindow } from '@app/shared/utils/window';
import { Constants } from '@app/shared/config/Constants';
import { NavbarTitle } from '@app/book/infra/config/NavbarTitle';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import FooterWeb from '@pedidosya/web-footer';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import Translations from './Layouts.translation';

export const MainLayout = () => {
  const { formatMessage } = useIntl();
  const { fire } = useWebViewEvents();
  const {
    country: { shortName },
  } = usePublicEnv();
  const navigate = useNavigate();
  const isWebView = useIsWebView();
  const location = useLocation();

  const onBack = useCallback(() => {
    if (Routes.BASE === location.pathname) {
      if (isWebView) {
        return fire(WebViewEvents.CLOSE, {});
      }
      return navigate(-1);
    }
    if (location.pathname.includes('success')) {
      if (isWebView) {
        return fire(WebViewEvents.CLOSE, {});
      }
      const wn = getWindow();
      if (wn) {
        wn.location.href = '/';
        return;
      }
    }
    return navigate(-1);
  }, [location]);

  const title = useMemo(() => {
    return (NavbarTitle as any)[shortName]
      ? formatMessage((Translations as any)[(NavbarTitle as any)[shortName].id])
      : '';
  }, [shortName]);

  return (
    <>
      <NavBar title={title} onBack={onBack} />
      <Outlet />
      {!isWebView ? (
        <FooterWeb countryCode={shortName} complaintBookUrl={Constants.NEW_URL_COMPLAINT_BOOK} />
      ) : (
        <></>
      )}
    </>
  );
};
