import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'home.title',
    defaultMessage: 'Libro de reclamaciones',
  },
  help_text: {
    id: 'home.help_text',
    defaultMessage:
      'En {link} podrás encontrar soluciones para distintas situaciones que necesites resolver rápidamente, también puedes ingresar una queja o reclamo a través del Libro de reclamaciones.',
  },
  type_complaint: {
    id: 'home.type_complaint',
    defaultMessage: '¿Qué es una queja y qué es un reclamo?',
  },
  after_complaint_title: {
    id: 'home.after_complaint.title',
    defaultMessage: '¿Qué pasa luego de que presento una queja o un reclamo?',
  },
  after_complaint_message: {
    id: 'home.after_complaint.message',
    defaultMessage:
      'Enviaremos a tu correo electrónico una copia de tu solicitud y en un plazo máximo de 15 días hábiles recibirás una respuesta.',
  },
  type_complaint_message_attention: {
    id: 'home.type_complaint.message_attention',
    defaultMessage:
      'La queja es la manifestación de tu descontento respecto a la atención que recibiste.',
  },
  type_complaint_message_service: {
    id: 'home.type_complaint.message_service',
    defaultMessage:
      'El reclamo es la expresión de tu disconformidad  con los servicios que brindamos.',
  },
  enter: {
    id: 'home.enter',
    defaultMessage: 'Ingresar queja o reclamo',
  },
  onlineHelp: {
    id: 'home.onlineHelp',
    defaultMessage: 'Ayuda en línea',
  },
  navbar_title_pe: {
    id: 'navbar.title_pe',
    defaultMessage: 'Libro de reclamaciones',
  },
  navbar_title_ar: {
    id: 'navbar.title_ar',
    defaultMessage: 'Libro de quejas online',
  },
});

export default messages;
