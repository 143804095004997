import React, { useMemo } from 'react';
import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Routes as RouterConfig } from '@app/book/infra/config/Routes';
import { Home } from '@app/book/presentation/pages/Home';
import { Error404 } from '@app/shared/components/Error404';
import { MainLayout } from '@app/book/presentation/Layouts';
import { ProtectedRouter } from './ProtectedRoute';
import { useComplaintContext } from '@app/book/data/contexts';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { CountriesAllowed } from '@app/book/infra/config/CountriesAllowed';
import loadable from '@loadable/component';
import BrandLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const ComplaintPage = loadable(() => import('@app/book/presentation/pages/Complaint'), {
  resolveComponent: (components) => components.Complaint,
  fallback: <BrandLoader position="center" />,
});

const ComplaintSuccessPage = loadable(
  () => import('@app/book/presentation/pages/ComplaintSuccess'),
  {
    resolveComponent: (components) => components.ComplaintSuccess,
    fallback: <BrandLoader position="center" />,
  },
);

const SelectCountryPage = loadable(() => import('@app/book/presentation/pages/SelectCountry'), {
  resolveComponent: (components) => components.SelectCountry,
  fallback: <BrandLoader position="center" />,
});

type RouterApp = {
  children?: JSX.Element | Array<JSX.Element>;
};

export const Router: FC<RouterApp> = () => {
  const { generated } = useComplaintContext();
  const { country } = usePublicEnv();

  /**
   * Validating only countries allowd
   */
  const countryPermission = useMemo(() => {
    if (!country) {
      return { redirectTo: RouterConfig.SELECT_COUNTRY, isAllowed: false };
    }
    if (!Object.values(CountriesAllowed).includes(country?.shortName)) {
      return { redirectTo: RouterConfig.SELECT_COUNTRY, isAllowed: false };
    }

    return { redirectTo: '/', isAllowed: true };
  }, [generated, country?.shortName]);

  /**
   * Validating permissions to success page
   */
  const successPage = useMemo(() => {
    const country = countryPermission;
    if (!country.isAllowed) {
      return country;
    }
    if (!generated) {
      return { redirectTo: RouterConfig.BASE, isAllowed: false };
    }

    return { redirectTo: '/', isAllowed: true };
  }, [generated, country?.shortName]);

  return (
    <Routes>
      <Route path={RouterConfig.BASE} element={<MainLayout />}>
        <Route
          path={RouterConfig.HOME}
          element={
            <ProtectedRouter
              isAllowed={countryPermission.isAllowed}
              redirectTo={countryPermission.redirectTo}
            >
              <Home />
            </ProtectedRouter>
          }
        />
        <Route
          path={RouterConfig.COMPLAINT}
          element={
            <ProtectedRouter
              isAllowed={countryPermission.isAllowed}
              redirectTo={countryPermission.redirectTo}
            >
              <ComplaintPage />
            </ProtectedRouter>
          }
        />
        <Route
          path={RouterConfig.COMPLAINT_SUCCESS}
          element={
            <ProtectedRouter isAllowed={successPage.isAllowed} redirectTo={successPage.redirectTo}>
              <ComplaintSuccessPage />
            </ProtectedRouter>
          }
        />
      </Route>
      <Route path={RouterConfig.SELECT_COUNTRY} element={<SelectCountryPage />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
