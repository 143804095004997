import React from 'react';
import type { FC, HTMLAttributes } from 'react';
import { Container } from '@app/shared/components/Container';
import { Content } from './NavBar.style';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Navbar from '@pedidosya/web-fenix/organisms/Navbar';
import ChevronLeft from '@pedidosya/web-fenix/icons/ChevronLeft';
import IconButton from '@pedidosya/web-fenix/atoms/IconButton';

interface NavBarProps extends HTMLAttributes<HTMLElement> {
  title: string;
  onBack(): void;
}

export const NavBar: FC<NavBarProps> = ({ title, onBack }) => {
  return (
    <Navbar>
      <Container pBottom="16">
        <Content>
          <IconButton icon={<ChevronLeft />} size="medium" withStroke={false} onClick={onBack} />
          <Typography token="font-subtitle-midcontrast-sentence-large">{title}</Typography>
        </Content>
      </Container>
    </Navbar>
  );
};
