import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, createContext } from 'react';
import BrandLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import Error from '@pedidosya/web-fenix/pages/Error';
import { handleItem } from '@app/shared/utils/storage';
import { Constants } from '@app/shared/config/Constants';
import { Urls } from '@app/shared/config/Urls';
import { HttpClient } from '@app/shared/utils/http';

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const { data } = await HttpClient.getInstance().get(Urls.ENV);
  handleItem(Constants.MOCK_FLAG, data.mock);
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv) {
      handleItem(Constants.MOCK_FLAG, publicEnv.mock);
      return;
    }
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, [getPublicEnv, publicEnv]);

  if (error)
    return <Error primaryActionClick={() => {}} primaryActionLabel="Tuvimos un problema" />;

  if (!publicEnv) return <BrandLoader position="center" />;

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
