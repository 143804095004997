import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.space('space-08')};
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Box = styled.div`
  flex: 1;
  width: 50%;
  box-sizing: border-box;
  @media (max-width: 600px) {
    width: fit-content;
    min-width: 200px;
    display: inline-block;
  }
`;

type TitleProps = {
  textAlign: string;
  margin: string;
};

export const Title = styled<TitleProps & typeof Typography>(Typography)`
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  & > div {
    display: inline-block;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`;

export const FormContent = styled.div`
  max-width: 920px;
  width: 100%;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: ${(props) => props.theme.color('text-color-primary')};
  font-weight: 700;
`;
